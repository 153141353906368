import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import { Collapse } from '@mui/material';
import { IntegrateSeuratObjectFormValues } from '../PreprocessFormTypes';
import AnalysisVariablesMultiSelectField from '../../analyses/assaySummary/AnalysisVariablesMultiSelectField';
import { useExperimentWorkflowContext } from '@contexts/ExperimentWorkflowContext';
import { ParameterOption } from '@models/AnalysisParameters';
import DisclosureAccordion from '@/src/components/DisclosureAccordion';
import { WarningRounded } from '@mui/icons-material';
import TextInputField from '@/src/components/forms/TextInputField';

type Props = {
    readOnly: boolean;
};
const IntegrateSeuratObjectFormFields = ({ readOnly }: Props) => {
    const { values, setFieldValue } = useFormikContext<IntegrateSeuratObjectFormValues>();
    const [visualizationExpanded, setVisualizationExpanded] = useState(false);
    const { preprocessParameters, selectedWorkflow } = useExperimentWorkflowContext();
    const params = preprocessParameters?.find((param) => param.workflowId === selectedWorkflow?.uuid);
    const covariates = params?.preprocessParameters?.int_factors?.map((c, index) => ({
        id: index,
        display_name: c,
    })) as ParameterOption[];
    const isNotHarmony = values.int_method === 'cca' || values.int_method === 'rpca';

    return (
        <div className="space-y-8">
            <section>
                <DisclosureAccordion
                    title="Important preprocess info"
                    Icon={<WarningRounded className="w-4 text-amber-500" />}
                >
                    <p>
                        Integration is an optional step and is not always required. Integration is recommended if your
                        data showed unexpected variability across samples and were grouped by sample or by experimental
                        covariate during the Normalize step. Read the{' '}
                        <span className="font-semibold">Instructions & Tips</span> to learn more about when to integrate
                        your data.
                    </p>
                </DisclosureAccordion>
            </section>
            <section>
                <div className="space-y-2">
                    <label className="block cursor-pointer">
                        <input
                            type="radio"
                            className="text-indigo-500"
                            name="int_method"
                            checked={values.int_method === 'skip'}
                            onChange={() => {
                                setFieldValue('int_method', 'skip');
                                setFieldValue('covariates', []);
                            }}
                            disabled={readOnly}
                        />
                        <span className="ml-2">No integration</span>
                    </label>

                    <label className="block cursor-pointer">
                        <input
                            type="radio"
                            className="text-indigo-500"
                            checked={values.int_method !== 'skip'}
                            onChange={() => {
                                setFieldValue('int_method', 'harmony');
                                setFieldValue('covariates', []);
                            }}
                            disabled={readOnly}
                        />
                        <span className="ml-2">Integrate data</span>
                    </label>
                </div>
            </section>
            {values.int_method !== 'skip' && (
                <>
                    <section>
                        <p className="mb-2 text-lg font-semibold text-dark">Integration Method</p>
                        <div className="space-y-2">
                            <label className="block cursor-pointer">
                                <input
                                    type="radio"
                                    className="text-indigo-500"
                                    name="int_method"
                                    onChange={() => {
                                        setFieldValue('int_method', 'harmony');
                                        setFieldValue('covariates', []);
                                    }}
                                    checked={values.int_method === 'harmony'}
                                    disabled={readOnly}
                                />
                                <span className="ml-2">Harmony (recommended)</span>
                            </label>
                            <label className="block cursor-pointer">
                                <input
                                    type="radio"
                                    className="text-indigo-500"
                                    name="int_method"
                                    checked={values.int_method === 'rpca' ?? false}
                                    onChange={() => {
                                        setFieldValue('int_method', 'rpca');
                                        setFieldValue('covariates', []);
                                    }}
                                    disabled={readOnly}
                                />
                                <span className="ml-2">Reciprocal Principal Component Analysis (RPCA)</span>
                            </label>
                            <label className="block cursor-pointer">
                                <input
                                    type="radio"
                                    className="text-indigo-500"
                                    name="int_method"
                                    checked={values.int_method === 'cca' ?? false}
                                    onChange={() => {
                                        setFieldValue('int_method', 'cca');
                                        setFieldValue('covariates', []);
                                    }}
                                    disabled={readOnly}
                                />
                                <span className="ml-2">Canonical Correlation Analysis (CCA)</span>
                            </label>
                        </div>

                        <section className="mt-8">
                            <AnalysisVariablesMultiSelectField
                                label={isNotHarmony ? 'Covariate' : 'Covariate(s)'}
                                variables={covariates}
                                disableGroups
                                maxItems={isNotHarmony ? 1 : undefined}
                                disabled={readOnly}
                                variablesName="covariates"
                                useVariableName
                            />
                        </section>
                    </section>
                    <section>
                        <h4
                            className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                            onClick={() => setVisualizationExpanded(!visualizationExpanded)}
                        >
                            <span>Visualization</span>
                            <span>
                                <ChevronRightIcon
                                    width={20}
                                    className={cn(
                                        'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                                        { 'rotate-90': visualizationExpanded },
                                    )}
                                />
                            </span>
                        </h4>
                        <Collapse in={visualizationExpanded}>
                            <div className="space-y-6">
                                <TextInputField
                                    name="n_dims"
                                    label="Number of principal components"
                                    type="number"
                                    value={values.n_dims ?? ''}
                                    disabled={readOnly}
                                    onValueChange={(_, __, values) => setFieldValue('n_dims', values?.float ?? 0)}
                                    fixedDecimalLength={0}
                                    allowNegativeValue={false}
                                    step={1}
                                    min={0}
                                    useNumericFormat
                                />
                                <TextInputField
                                    name="n_neighbors"
                                    label="Number of neighboring points (n.neighbors)"
                                    type="number"
                                    value={values.n_neighbors ?? ''}
                                    disabled={readOnly}
                                    onValueChange={(_, __, values) => setFieldValue('n_neighbors', values?.float ?? 0)}
                                    fixedDecimalLength={0}
                                    allowNegativeValue={false}
                                    step={1}
                                    min={0}
                                    useNumericFormat
                                />
                            </div>
                        </Collapse>
                    </section>
                </>
            )}
        </div>
    );
};

export default IntegrateSeuratObjectFormFields;
