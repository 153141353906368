import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import { Collapse } from '@mui/material';
import CheckboxField from '@/src/components/forms/CheckboxField';
import { NormalizeSeuratObjectFormValues } from '../PreprocessFormTypes';
import TextInputField from '@/src/components/forms/TextInputField';

type Props = {
    readOnly: boolean;
};
const NormalizeSeuratObjectFormFields = ({ readOnly }: Props) => {
    const { values, setFieldValue } = useFormikContext<NormalizeSeuratObjectFormValues>();
    const [optionsExpanded, setOptionsExpanded] = useState(false);
    const [visualizationExpanded, setVisualizationExpanded] = useState(false);
    const [removeVariationExpanded, setRemoveVariationExpanded] = useState(false);
    return (
        <div className="space-y-8">
            <section>
                <p className="mb-2 text-lg font-semibold text-dark">Normalization Method</p>
                <div className="space-y-2">
                    <label className="block cursor-pointer">
                        <input
                            type="radio"
                            className="text-indigo-500"
                            name="lognormalize"
                            checked={values.norm_method === 'lognormalize' ?? false}
                            onChange={() => {
                                setFieldValue('norm_method', 'lognormalize');
                            }}
                            disabled={readOnly}
                        />
                        <span className="ml-2">Global-scaling (log normalized) (recommended)</span>
                    </label>
                    <label className="block cursor-pointer">
                        <input
                            type="radio"
                            className="text-indigo-500"
                            name="norm_method"
                            onChange={() => {
                                setFieldValue('norm_method', 'sctransform');
                            }}
                            checked={values.norm_method === 'sctransform'}
                            disabled={readOnly}
                        />
                        <span className="ml-2">SCTransform</span>
                    </label>
                </div>
            </section>
            <section>
                <h4
                    className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                    onClick={() => setOptionsExpanded(!optionsExpanded)}
                >
                    <span>Options</span>
                    <span>
                        <ChevronRightIcon
                            width={20}
                            className={cn(
                                'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                                { 'rotate-90': optionsExpanded },
                            )}
                        />
                    </span>
                </h4>
                <Collapse in={optionsExpanded}>
                    <div className="space-y-6">
                        <TextInputField
                            name="n_features"
                            label="Number of top variable features"
                            type="number"
                            value={values.n_features ?? 0}
                            noMargin
                            disabled={readOnly}
                            onValueChange={(_, __, values) => setFieldValue('n_features', values?.float ?? 0)}
                            fixedDecimalLength={0}
                            allowNegativeValue={false}
                            step={1}
                            min={1000}
                            max={5000}
                            useNumericFormat
                        />
                    </div>
                </Collapse>
            </section>
            <section className="!mt-4">
                <h4
                    className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                    onClick={() => setRemoveVariationExpanded(!removeVariationExpanded)}
                >
                    <span>Remove unwanted variation</span>
                    <span>
                        <ChevronRightIcon
                            width={20}
                            className={cn(
                                'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                                { 'rotate-90': removeVariationExpanded },
                            )}
                        />
                    </span>
                </h4>
                <Collapse in={removeVariationExpanded}>
                    <div className="space-y-6">
                        <CheckboxField
                            className="flex cursor-pointer items-start"
                            checkboxClassName="mt-1"
                            name="regress_umi"
                            label={
                                <>
                                    <h2 className="font-semibold tracking-tight text-gray-500">
                                        Regress out UMI count effect
                                    </h2>
                                    <p className="text-gray-500">Remove variation due to UMI counts</p>
                                </>
                            }
                            disabled={readOnly}
                        />
                        <CheckboxField
                            className="flex cursor-pointer items-start"
                            checkboxClassName="mt-1"
                            name="regress_mito"
                            label={
                                <>
                                    <h2 className="font-semibold tracking-tight text-gray-500">
                                        Regress out % mito effect
                                    </h2>
                                    <p className="text-gray-500">Remove variation due to mitochondrial expression</p>
                                </>
                            }
                            disabled={readOnly}
                        />
                        <CheckboxField
                            className="flex cursor-pointer items-start"
                            checkboxClassName="mt-1"
                            name="regress_cc_diff"
                            label={
                                <>
                                    <h2 className="font-semibold tracking-tight text-gray-500">
                                        Regress out cell cycle effect (difference)
                                    </h2>
                                    <p className="text-gray-500">
                                        Regress out the difference between G2M and S phase scores (maintain signals
                                        separating non-cycling and cycling cells)
                                    </p>
                                </>
                            }
                            disabled={readOnly}
                        />
                        <CheckboxField
                            className="flex cursor-pointer items-start"
                            checkboxClassName="mt-1"
                            name="regress_cc"
                            label={
                                <>
                                    <h2 className="font-semibold tracking-tight text-gray-500">
                                        Regress out cell cycle effect (score)
                                    </h2>
                                    <p className="text-gray-500">Remove all signal associated with cell cycle</p>
                                </>
                            }
                            disabled={readOnly}
                        />
                    </div>
                </Collapse>
            </section>
            <section className="!mt-4">
                <h4
                    className="group mb-4 flex cursor-pointer flex-row items-center justify-between overflow-hidden text-lg font-semibold tracking-tight text-dark"
                    onClick={() => setVisualizationExpanded(!visualizationExpanded)}
                >
                    <span>Visualization</span>
                    <span>
                        <ChevronRightIcon
                            width={20}
                            className={cn(
                                'cursor-pointer text-primary transition-transform  hover:text-primary/80 group-hover:text-primary/80',
                                { 'rotate-90': visualizationExpanded },
                            )}
                        />
                    </span>
                </h4>
                <Collapse in={visualizationExpanded}>
                    <div className="space-y-6">
                        <TextInputField
                            name="n_dims"
                            label="Number of principal components"
                            type="number"
                            value={values.n_dims ?? 0}
                            disabled={readOnly}
                            onValueChange={(_, __, values) => setFieldValue('n_dims', values?.float ?? 0)}
                            fixedDecimalLength={0}
                            allowNegativeValue={false}
                            step={1}
                            min={0}
                            useNumericFormat
                        />
                        <TextInputField
                            name="n_neighbors"
                            label="Number of neighboring points (n.neighbors)"
                            type="number"
                            value={values.n_neighbors ?? 0}
                            disabled={readOnly}
                            onValueChange={(_, __, values) => setFieldValue('n_neighbors', values?.float ?? 0)}
                            fixedDecimalLength={0}
                            allowNegativeValue={false}
                            step={1}
                            min={0}
                            useNumericFormat
                        />
                    </div>
                </Collapse>
            </section>
        </div>
    );
};

export default NormalizeSeuratObjectFormFields;
